import { BsFillMoonStarsFill } from "react-icons/bs";
import { FaLinkedin, FaRegEnvelope } from "react-icons/fa";
import { ImNewTab } from "react-icons/im";
import { useState } from "react";
import Lottie from "lottie-react";
import animationData from "./media/about.json";
import animationData2 from "./media/home.json";
import { Link } from "react-scroll";
import { GiHamburgerMenu, GiGrowth } from "react-icons/gi";
import { RxCross2 } from "react-icons/rx";
import {
  AiFillMediumSquare,
  AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import { MdWorkspacePremium } from "react-icons/md";
import { IoIosRocket } from "react-icons/io";
import "./App.css"
import Modal from './Modal';

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const App = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const [clicked, setClicked] = useState(false);

  const [showModal, setShowModal] = useState(false);

  const handleClick = () => {
    setClicked(true);
    setTimeout(() => setClicked(false), 100); // Reset the animation after 1 second
    // Add your form submission logic here
    setShowModal(true);

    setTimeout(() => setShowModal(false), 10000);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };


  const handleMenu = () => {
    setShowMenu(!showMenu);
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1274 },
      items: 3,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1274, min: 867 },
      items: 2,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 867, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };

  const projects = [
    {
      title: "E-commerce Platform",
      description:
        "A comprehensive online ecommerce solution with a user-friendly interface and secure checkout.",
      imageUrl: "/images/ecom.png",
      link: "https://www.channable.com/",
    },
    {
      title: "Online Learning Portal",
      description:
        "An interactive platform offering various online courses with video lectures and quizzes, and providing certificates upon completion.",
      imageUrl: "/images/edu.png",
      link: "https://www.sorbil.org/",
    },
    {
      title: "Hotel Management System",
      description:
        "An all-in-one solution for managing bookings, from registrations and ticketing to scheduling and promotions.",
      imageUrl: "/images/hotel.png",
      link: "https://www.9doorshotel.com/en/",
    },
    {
      title: "Tech Blog Platform",
      description:
        "A modern platform for tech enthusiasts, providing insightful articles and engaging content.",
      imageUrl: "/images/blog.png",
      link: "https://beetekno.com/",
    },
  ];

  return (
    <div className={darkMode ? "dark" : ""}>
      <div className="bg-white dark:bg-gray-900">
        {/* Navigation Bar and Hero Section */}

        <Modal show={showModal} onClose={handleCloseModal}>
        <p>Your form has been submitted successfully!</p>
        <button
          className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline mt-4"
          onClick={handleCloseModal}
        >
          Close
        </button>
      </Modal>

        <section id="home" className="min-h-screen relative">
          <div className="w-full shadow-xl dark:bg-gray-800 fixed z-10 bg-white">
            <nav className="w-full sm:w-11/12 max-w-screen-xl m-auto py-5 px-5 sm:px-0 flex justify-between items-center">
              <h1 className="text-xl dark:text-white">Benby.</h1>

              <div className="flex items-center">
                <ul className="hidden sm:flex sm:items-center">
                  <li>
                    <Link
                      className="mx-2 cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                      to="home"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mx-2 cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                      to="about"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      About
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mx-2 cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                      to="projects"
                      spy={true}
                      smooth={true}
                      duration={500}
                      offset={-25}
                    >
                      Projects
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="mx-2 cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                      to="pricing"
                      spy={true}
                      smooth={true}
                      duration={500}
                    >
                      Pricing
                    </Link>
                  </li>
                </ul>

                {showMenu ? (
                  <RxCross2
                    onClick={handleMenu}
                    className="text-3xl mx-2 sm:hidden cursor-pointer"
                  ></RxCross2>
                ) : (
                  <GiHamburgerMenu
                    onClick={handleMenu}
                    className="text-3xl mx-2 sm:hidden cursor-pointer"
                  ></GiHamburgerMenu>
                )}

                <Link
                  className="mx-2 bg-gradient-to-r from-cyan-500 to-teal-500 text-white px-4 py-2 rounded cursor-pointer"
                  to="contact"
                  spy={true}
                  smooth={true}
                  duration={500}
                  offset={-70}
                >
                  Contact
                </Link>

                <BsFillMoonStarsFill
                  onClick={() => setDarkMode(!darkMode)}
                  className="cursor-pointer text-2xl dark:text-white"
                ></BsFillMoonStarsFill>
              </div>
            </nav>

            <div className={showMenu ? "duration-500" : "duration-500 hidden"}>
              <ul className="flex flex-col justify-center items-center text-center">
                <li className="p-2 w-full border-y border-black">
                  <Link
                    className="cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                    to="home"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={handleMenu}
                  >
                    Home
                  </Link>
                </li>
                <li className="p-2 w-full border-b border-black">
                  <Link
                    className="cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                    to="about"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={handleMenu}
                  >
                    About
                  </Link>
                </li>
                <li className="p-2 w-full border-b border-black">
                  <Link
                    className="cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                    to="projects"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={handleMenu}
                  >
                    Projects
                  </Link>
                </li>
                <li className="p-2 w-full border-b border-black">
                  <a
                    href="https://medium.com/@yusufyaydemir"
                    className="mx-2 cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                    target="blank"
                    onClick={handleMenu}
                  >
                    Blog
                  </a>
                </li>
                <li className="p-2">
                  <Link
                    className="cursor-pointer hover:text-teal-600 dark:hover:text-teal-600 duration-300 dark:text-white"
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    onClick={handleMenu}
                  >
                    Contact
                  </Link>
                </li>
              </ul>
            </div>
          </div>

          <div className=" w-11/12 max-w-screen-xl m-auto text-center py-10">
            <div className="mt-20 relative mx-auto w-3/4 sm:w-2/4 md:w-1/4 lg:w-96  mb-10 sm:mb-20">
              <Lottie animationData={animationData2}></Lottie>
            </div>

            <h2 className="text-5xl py-2 text-teal-600 font-medium md:text-6xl">
              Benby.
            </h2>
            <h3 className="text-2xl py-2 md:text-3xl dark:text-white">
              World's Top Leading Full-Service Digital Agency
            </h3>
            <p className="text-md py-5 leading-8 text-gray-800 md:text-xl max-w-xl mx-auto dark:text-white">
              Benby converts your ideas into reality with comprehensive business
              solutions.
            </p>
          </div>
        </section>

        {/* About Section */}

        <section
          id="about"
          className="w-11/12 max-w-screen-xl min-h-screen m-auto flex flex-col xl:flex-row items-center justify-center pt-[8rem] sm:pt-0"
        >
          <div>
            <Lottie
              animationData={animationData}
              className="w-3/4 sm:w-2/4 md:w-1/4 lg:w-96 m-auto lg:m-0 bg-gray-200 rounded-md"
            ></Lottie>
          </div>
          <div className="w-full lg:w-3/4 pl-0 lg:pl-10 flex flex-col justify-center text-center sm:text-left">
            <h3 className="text-3xl sm:text-5xl py-1 text-teal-600">
              About Us
            </h3>
            <p className="text-md sm:text-lg pt-2 leading-8 text-gray-800 dark:text-white">
              Welcome to Benby, your premier partner for comprehensive business
              solutions and digital transformation. With a passionate team of
              seasoned developers, designers, marketers, and strategists, we
              specialize in creating custom solutions that drive business
              success. From company formation and brand creation to market
              research, marketing campaigns, web design, mobile app development,
              and website development, our innovative approach ensures seamless
              integration and exceptional performance. At Benby, we are
              committed to delivering excellence through collaboration,
              creativity, and technology, empowering your business to thrive in
              the digital age.
              <Link
                className="text-teal-600 cursor-pointer"
                to="contact"
                spy={true}
                smooth={true}
                duration={500}
                offset={-70}
              >
                {" "}
                Contact now.
              </Link>
            </p>
          </div>
        </section>

        {/* Projects Section */}

        <section
          id="projects"
          className="w-11/12 max-w-screen-xl m-auto pt-20 pb-10 sm:pb-0 min-h-screen"
        >
          <div className="flex flex-col justify-center">
            <h3 className="text-3xl sm:text-5xl py-1 text-teal-600">
              Projects
            </h3>
            <p className="text-md sm:text-lg pt-2 leading-8 text-gray-800 dark:text-white">
              At Benby, we take pride in showcasing the innovative and impactful
              work we deliver for our clients. Our recent projects span a
              diverse range of industries and services, reflecting our
              commitment to excellence and versatility. From dynamic web designs
              and robust mobile applications to successful marketing campaigns
              and comprehensive market research, each project is a testament to
              our dedication to converting ideas into reality. Explore our
              portfolio to see how we've helped businesses achieve their goals
              and thrive in the digital landscape.
            </p>
          </div>

          <div className="mt-10 py-10">
            <Carousel responsive={responsive}>
              {projects.map((project) => (
                <div className="shadow-lg rounded-md bg-gray-200 w-80 sm:w-96">
                  <a
                    href={project.link}
                    target="blank"
                    className="cursor-pointer"
                  >
                    <img
                      src={project.imageUrl}
                      alt="Tech Blog Platform"
                      className="w-full h-64 object-fill"
                    />
                    <div className="flex items-center justify-between p-5">
                      <div className="flex flex-col items-center justify-center">
                        <h2 className="text-gray-800">{project.title}</h2>
                        <p className="text-gray-800">{project.description}</p>
                      </div>
                      <ImNewTab className="text-gray-800 text-2xl" />
                    </div>
                  </a>
                </div>
              ))}
            </Carousel>
          </div>
        </section>

        {/* Pricing Section */}

        <section
          id="pricing"
          className="w-11/12 max-w-screen-xl m-auto pt-20 pb-10 sm:pb-0 min-h-screen"
        >
          <div className="py-5">
            <h3 className="text-3xl sm:text-5xl py-1 text-teal-600">Pricing</h3>
            <p className="dark:text-white text-lg">
              Our flexible pricing plans are designed to meet the diverse needs
              of our clients, ensuring top-quality solutions that fit your
              budget.
            </p>
          </div>

          <div className="py-10 sm:py-20 w-full flex flex-col items-center justify-center">
            <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10">
              {/* Starter Package */}
              <div className="shadow-lg rounded-md bg-gray-200 p-6 flex flex-col items-center justify-between">
                <div className="flex flex-col items-center">
                  <h2 className="text-2xl text-teal-600 flex items-center">
                    Starter Package <AiOutlineFundProjectionScreen />
                  </h2>
                  <p className="text-gray-800 my-4 text-center">
                    This package is perfect for new businesses looking to
                    establish a solid foundation with a professional brand and
                    online presence.
                  </p>
                  <ul className="list-disc ml-5 text-gray-800">
                    <li>Company Formation</li>
                    <li>Brand Creation</li>
                    <li>Market Research</li>
                    <li>Web Development</li>
                  </ul>
                </div>

                <div>
                  <p className="text-2xl text-teal-600 mt-4 text-center">
                    $3,000
                  </p>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="mt-6 inline-block bg-gradient-to-r from-cyan-500 to-teal-500 text-white px-4 py-2 rounded cursor-pointer"
                  >
                    Contact
                  </Link>
                </div>
              </div>

              {/* Growth Package */}
              <div className="shadow-lg rounded-md bg-gray-200 p-6 flex flex-col items-center justify-between">
                <div className="flex flex-col items-center">
                  <h2 className="text-2xl text-teal-600 flex items-center">
                    Growth Package <GiGrowth />
                  </h2>
                  <p className="text-gray-800 my-4 text-center">
                    Designed for growing businesses, this package includes
                    comprehensive services to boost your brand and reach a wider
                    audience.
                  </p>
                  <ul className="list-disc ml-5 text-gray-800">
                    <li>Company Formation</li>
                    <li>Brand Creation</li>
                    <li>Market Research</li>
                    <li>Marketing Campaign</li>
                    <li>Web Design</li>
                    <li>Web Development</li>
                  </ul>
                </div>

                <div>
                  <p className="text-2xl text-teal-600 mt-4 text-center">
                    $7,500
                  </p>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="mt-6 inline-block bg-gradient-to-r from-cyan-500 to-teal-500 text-white px-4 py-2 rounded cursor-pointer"
                  >
                    Contact
                  </Link>
                </div>
              </div>

              {/* Premium Package */}
              <div className="shadow-lg rounded-md bg-gray-200 p-6 flex flex-col items-center justify-between">
                <div className="flex flex-col items-center">
                  <h2 className="text-2xl text-teal-600 flex items-center">
                    Premium Package <MdWorkspacePremium />
                  </h2>
                  <p className="text-gray-800 my-4 text-center">
                    Ideal for established businesses aiming for significant
                    market impact, this package offers an extensive suite of
                    services for a strong digital presence and market
                    penetration.
                  </p>
                  <ul className="list-disc ml-5 text-gray-800">
                    <li>Company Formation</li>
                    <li>Brand Creation</li>
                    <li>Market Research</li>
                    <li>Marketing Campaign</li>
                    <li>Web Design</li>
                    <li>Web Development</li>
                    <li>Mobile App Development</li>
                  </ul>
                </div>

                <div>
                  <p className="text-2xl text-teal-600 mt-4 text-center">
                    $15,000
                  </p>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="mt-6 inline-block bg-gradient-to-r from-cyan-500 to-teal-500 text-white px-4 py-2 rounded cursor-pointer"
                  >
                    Contact
                  </Link>
                </div>
              </div>

              {/* Ultimate Package */}
              <div className="shadow-lg rounded-md bg-gray-200 p-6 flex flex-col items-center justify-between">
                <div className="flex flex-col items-center">
                  <h2 className="text-2xl text-teal-600 flex items-center">
                    Ultimate Package <IoIosRocket />
                  </h2>
                  <p className="text-gray-800 my-4 text-center">
                    For businesses looking for comprehensive support and maximum
                    impact, the Ultimate Package provides everything needed to
                    dominate the digital landscape.
                  </p>
                  <ul className="list-disc ml-5 text-gray-800">
                    <li>Company Formation</li>
                    <li>Brand Creation</li>
                    <li>Market Research</li>
                    <li>Marketing Campaign</li>
                    <li>Web Design</li>
                    <li>Web Development</li>
                    <li>Mobile App Development</li>
                    <li>Support & Maintenance</li>
                  </ul>
                </div>

                <div>
                  <p className="text-2xl text-teal-600 mt-4 text-center">
                    $25,000
                  </p>
                  <Link
                    to="contact"
                    spy={true}
                    smooth={true}
                    duration={500}
                    offset={-70}
                    className="mt-6 inline-block bg-gradient-to-r from-cyan-500 to-teal-500 text-white px-4 py-2 rounded cursor-pointer"
                  >
                    Contact
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Contact Section */}

        <section
          id="contact"
          className="w-11/12 max-w-screen-xl m-auto min-h-screen flex flex-col justify-center mt-10 pt-20 sm:pt-0 items-center sm:items-start"
        >
          <p className="text-5xl sm:text-6xl md:text-7xl lg:text-8xl xl:text-9xl text-teal-600 font-bold text-center sm:text-left">
            Interested? <br /> Let's Get in Touch!
          </p>
          <p className="py-10 w-3/4 text-md sm:text-lg md:text-xl lg:text-2xl leading-8 text-gray-800 dark:text-white">
            To get in touch quickly, please fill out the form below.
          </p>

          <div className="w-full md:w-2/3">
            <form>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Name
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="name"
                  type="text"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="mb-4">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="email"
                  required
                >
                  Email
                </label>
                <input
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="email"
                  type="email"
                  placeholder="Your Email"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2">
                  Services Interested In
                </label>
                <div className="flex flex-wrap">
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service1"
                      />
                      Company Formation
                    </label>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service2"
                      />
                      Brand Creation
                    </label>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service3"
                      />
                      Market Research
                    </label>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service3"
                      />
                      Marketing Campaign
                    </label>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service3"
                      />
                      Web Design
                    </label>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service3"
                      />
                      Web Development
                    </label>
                  </div>
                  <div className="w-full sm:w-1/2 lg:w-1/3 mb-4">
                    <label className="block text-gray-700 dark:text-gray-300 text-sm font-bold">
                      <input
                        type="checkbox"
                        className="mr-2 leading-tight"
                        name="services"
                        value="service3"
                      />
                      Mobile App Development
                    </label>
                  </div>
                </div>
              </div>
              <div className="mb-6">
                <label
                  className="block text-gray-700 dark:text-gray-300 text-sm font-bold mb-2"
                  htmlFor="message"
                >
                  Message
                </label>
                <textarea
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 dark:text-gray-300 dark:bg-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                  id="message"
                  rows="4"
                  placeholder="Your Message"
                ></textarea>
              </div>
              <div className="flex items-center justify-between">
                <button
                  // className="bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                  type="button"
                  className={`bg-teal-500 hover:bg-teal-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline ${
                    clicked ? 'animate-size' : '' // Add the animate-pulse class when clicked
                  }`}
                  onClick={handleClick}
                >
                  Send
                </button>
              </div>
            </form>
          </div>
        </section>

        {/* Footer */}
        <footer className="w-full shadow-xl dark:bg-gray-800 py-6 mt-10">
          <div className="w-11/12 max-w-screen-xl m-auto flex flex-col items-center justify-between sm:flex-row text-center sm:text-left">
            <p className="text-black dark:text-white text-sm">
              &copy; {new Date().getFullYear()} Benby LLC. All rights reserved.
            </p>
            <div className="flex items-center space-x-4 mt-4 sm:mt-0">
              <a href="https://www.linkedin.com" target="_blank">
                <FaLinkedin className="text-black dark:text-white text-xl" />
              </a>
              <a href="https://medium.com/" target="_blank">
                <AiFillMediumSquare className="text-black dark:text-white text-xl" />
              </a>
              <a href="mailto:contact@benby.com">
                <FaRegEnvelope className="text-black dark:text-white text-xl" />
              </a>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default App;
